<template>
  <div class="ui grid container-1">
    <div class="two column row stackable">
      <div class="column details">
        <div class="content">
          <h2>EXPLORE</h2>
          <h1>INSTAPAY</h1>
          <h3>IN YOUR TAYOCASH WALLET</h3>

          <p>Faster, safer and hassle-free transactions <br>
            though QR PH via Instapay.
          </p>
          <div class="buttons">
            <router-link class="white-btn" to="/instapay" v-scroll-to="'#ciinsta'">
              CASH IN
            </router-link>
            <router-link class="white-btn" to="/instapay" v-scroll-to="'#ftinsta'">
              TRANSFER FUNDS
            </router-link>
            <router-link class="white-btn" to="/instapay" v-scroll-to="'#qrinsta'">
              PAY VIA QR PH
            </router-link>
          </div>
        </div>
      </div>
      <div class="column phone">
        <img src="@/assets/wn1phone.png">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'content1'
}
</script>

<style scoped lang='scss'></style>
